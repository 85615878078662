import React from 'react';

import ReactMarkdown from 'react-markdown'
import { DateTime, Duration } from "luxon"
import Cookies from 'js-cookie';
import { Button, Form, Table } from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';

import "../css/resource.css";

class Resource extends React.Component {
    constructor(props) {
        super(props);
        let localDate = DateTime.local();

        console.log(props);

        this.state = {
            resource: this.props.resource,
            isLoaded: false,
            reload: false,
            username: null,
            displayName: null,
            description: null,
            daysBookable: null,
            slots: [],
            timeSlotStart: null,
            timeSlotDuration: null,
            startDate: localDate.toSeconds() - ((localDate.hour * 60 * 60) + (localDate.minute * 60) + (localDate.second) + (localDate.millisecond / 1000)),
            selectedDate: null
        };

        this.dateSelectHandler = this.dateSelectHandler.bind(this);
    }

    componentDidMount() {
        this.initResource(this.state.resource);
    }

    async getUsername() {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(this.props.serverURL + "/api/whoami?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), { headers } );

        if(response.status !== 200) {
            window.location.replace(window.location.protocol + "//" + window.location.host);
        } else {
            try {
                let data = await response.json();

                return data.name;
            } catch (e) {
                console.error("ERROR: getting username");
            }
        }
    }

    async initResource(resource) {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(this.props.serverURL + "/api/resource/" + resource + "?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), { headers } );

        if(response.status !== 200) {
            window.location.replace(window.location.protocol + "//" + window.location.host);
        } else {
            try {
                let data = await response.json();
                let username = await this.getUsername();

                this.setState({
                    username: username,
                    timeSlotStart: data.timeslotStart,
                    timeSlotDuration: data.timeslotDauer,
                    selectedDate: this.state.startDate,
                    description: data.description,
                    daysBookable: data.daysBookable
                }, () => {
                    this.getTimeSlots();
                });
            } catch (e) {
                console.error("Error: loading resource");
            }
        }
    }

    async getTimeSlots() {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(this.props.serverURL + "/api/resource/" + this.state.resource + "/timeslots?start=" + this.timeToSlot(parseInt(this.state.selectedDate)) + "&end=" + this.timeToSlot(parseInt(this.state.selectedDate) + (24 * 60 * 60)) + "&sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), { headers } );


        if(response.status !== 200) return response.status;

        try {
            let slots = await response.json();

            this.setState({
                slots: slots,
                reload: false
            }, () => {
                if(!this.state.isLoaded) {
                    this.setState({
                        isLoaded: true
                    }, () => {
                        return 200;
                    });
                }
            });
        } catch (e) {
            this.setState({
                slots: []
            }, () => {
                if(!this.state.isLoaded) {
                    console.error("Error: loading time slots");
                } else {
                    console.error(("Error: updating time slots"));
                }

                return 400;
            });
        }
    }

    timeToSlot(timeInSeconds) {
        return ((timeInSeconds - DateTime.fromISO(this.state.timeSlotStart).toSeconds()) / Duration.fromISO(this.state.timeSlotDuration).as('seconds'));
    }

    slotToTime(timeSlot) {
        return (DateTime.fromISO(this.state.timeSlotStart).toSeconds() + (Duration.fromISO(this.state.timeSlotDuration).as('seconds') * timeSlot));
    }

    dateSelectHandler(event) {
        this.setState({
            reload: true,
            selectedDate: event.target.value,
        }, () => {
            this.getTimeSlots();
        });
    }

    async book(timeSlot, warning) {
        if(warning !== "") {
            if(!window.confirm(warning)) {
                this.setState({
                    reload: true,
                }, () => {
                    this.getTimeSlots();
                });

                return;
            }
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: ""
        };
        const response = await fetch(this.props.serverURL + "/api/resource/" + this.state.resource + "/timeslots/" + timeSlot + "?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), requestOptions);

        
        if(response.status === 200) {
            this.setState({
                reload: true,
            }, () => {
                this.getTimeSlots();
            });
        } else {
            this.setState({
                reload: true,
            }, () => {
                this.getTimeSlots();
            });
        }
    }

    async unbook(timeSlot) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: ""
        };
        const response = await fetch(this.props.serverURL + "/api/resource/" + this.state.resource + "/timeslots/" + timeSlot + "?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), requestOptions);

        if(response.status === 200) {
            this.setState({
                reload: true,
            }, () => {
                this.getTimeSlots();
            });
        } else {
            this.setState({
                reload: true,
            }, () => {
                this.getTimeSlots();
            });
        }
    }

    renderBookButton(index) {
        const slot = this.state.slots[index];

        let timeSlot = slot.timeslotNumber;

        if(slot.type === "unbookable") {
            return <Button className={timeSlot} variant={"secondary"} disabled>unbookable</Button>
        } else if(slot.type === "free") {
            if(slot.warning === "") {
                return <Button className={timeSlot} variant={"primary"} onClick={() => this.book(timeSlot, slot.warning)}>book</Button>
            } else {
                return <Button className={timeSlot} variant={"warning"} onClick={() => this.book(timeSlot, slot.warning)}>book</Button>
            }
        } else if(slot.type === "booked" && slot.user === this.state.username) {
            return <Button className={timeSlot} variant={"danger"} onClick={() => this.unbook(timeSlot)}>unbook</Button>
        } else if(slot.type === "booked") {
            return <Button className={timeSlot} variant={"secondary"} disabled>booked</Button>
        }
    }

    renderBookedBy(index) {
        const slot = this.state.slots[index];

        let username = slot.user;

        if(username === "") {
            return "---";
        }

        return username;
    }

    getTimeStamp(date) {
        let time = DateTime.fromSeconds(date).setLocale("de").toFormat('dd.MM.yyyy');

        return <option key={date} value={date}>{time} - {DateTime.fromSeconds(date).setLocale("de").toFormat('EEE')}</option>

        /*switch (DateTime.fromSeconds(date).setLocale("de").toFormat('EEE')) {
            case "Mo.":
                return <option key={date} value={date}>Mo.&nbsp;&nbsp;{time}</option>
            case "Di.":
                return <option key={date} value={date}>Di.&nbsp;&nbsp;&nbsp;&nbsp;{time}</option>
            case "Mi.":
                return <option key={date} value={date}>Mi.&thinsp;&nbsp;&nbsp;&nbsp;{time}</option>
            case "Do.":
                return <option key={date} value={date}>Do.&nbsp;&nbsp;&nbsp;{time}</option>
            case "Fr.":
                return <option key={date} value={date}>Fr.&thinsp;&nbsp;&nbsp;&nbsp;&nbsp;{time}</option>
            case "Sa.":
                return <option key={date} value={date}>Sa.&nbsp;&nbsp;&nbsp;{time}</option>
            case "So.":
                return <option key={date} value={date}>So.&nbsp;&nbsp;&nbsp;{time}</option>
            default:
                return <option key={date} value={date}>??.&nbsp;{time}</option>
        }*/
    }

    renderDateSelect() {
        const { daysBookable } = this.state;

        let dates = [];
        let j = 0;
        let oldStringDate = "";
        let newStringDate = "";
        for(let i = 0; i < daysBookable; i++) {
            let dtDate = DateTime.fromSeconds(this.state.startDate + (j * (24 * 60 * 60))).startOf("day");
            newStringDate = dtDate.toSeconds();

            if (newStringDate === oldStringDate) {
                j++;
                dtDate = DateTime.fromSeconds(this.state.startDate + (j * (24 * 60 * 60))).startOf("day");
            }

            dates.push(dtDate.toSeconds());
            oldStringDate = newStringDate;
            j++;
        }

        return (
            <Form className={"resourceDate"}>
                <select value={this.state.selectedDate} className={"custom-select"} onChange={this.dateSelectHandler}>
                    {dates.map((date, index) =>
                        this.getTimeStamp(date)
                    )}
                </select>
            </Form>
        );
    }

    renderTimeSlot(index) {
        let slots = this.state.slots[index];

        if(slots.type === "unbookable") {
            return;
        } else {
            return (
                <tr key={index}>
                    <td>{DateTime.fromSeconds(this.slotToTime(slots.timeslotNumber)).toFormat('HH:mm')} - {DateTime.fromSeconds(this.slotToTime(slots.timeslotNumber+1)).toFormat('HH:mm')}</td>
                    <td>{this.renderBookedBy(index)}</td>
                    <td>{this.renderBookButton(index)}</td>
                </tr>
            );
        }
    }

    renderPrevButton() {
        let startDate = this.state.startDate;

        let dtcurrDate = DateTime.fromSeconds(parseInt(this.state.selectedDate)).startOf("day");
        let dtselDate = DateTime.fromSeconds( parseInt(this.state.selectedDate) - (1 * (24 * 60 * 60))).startOf("day");

        if (dtcurrDate.toSeconds() === dtselDate.toSeconds()) {
            dtselDate = DateTime.fromSeconds( parseInt(this.state.selectedDate) - (2 * (24 * 60 * 60))).startOf("day");
        }

        if(dtselDate.toSeconds() < startDate) {
            return <Button className={"prev"} variant={"secondary"} disabled>Previous</Button>
        } else {
            return <Button className={"prev"} variant={"primary"} value={dtselDate.toSeconds()} onClick={this.dateSelectHandler}>Previous</Button>
        }
    }

    renderNextButton() {
        const { daysBookable } = this.state;

        let startDate = this.state.startDate;

        let dtcurrDate = DateTime.fromSeconds(parseInt(this.state.selectedDate)).startOf("day");
        let dtselDate = DateTime.fromSeconds( parseInt(this.state.selectedDate) + (1 * (24 * 60 * 60))).startOf("day");

        if (dtcurrDate.toSeconds() === dtselDate.toSeconds()) {
            dtselDate = DateTime.fromSeconds( parseInt(this.state.selectedDate) + (2 * (24 * 60 * 60))).startOf("day");
        }

        if(dtselDate.toSeconds() > startDate + ((daysBookable - 1) * (24 * 60 * 60)) + (60 * 60)) {
            return <Button className={"next"} variant={"secondary"} disabled>Next</Button>
        } else {
            return <Button className={"next"} variant={"primary"} value={dtselDate.toSeconds()} onClick={this.dateSelectHandler}>Next</Button>
        }
    }

    render() {
        const { slots, isLoaded, reload, description } = this.state;

        if(!isLoaded) {
            return (
                <div className={'loading-resource'}>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            )
        } else if(reload) {
            return(
                <div className={'resource'}>
                    <div className="spinner-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            )
        } else {
            return(
                <div>
                    <div className={"res-head"}>
                        <ReactMarkdown>{description}</ReactMarkdown>
                    </div>
                    <div className={'resource'}>
                        <div className={'res_select'}>
                            {this.renderPrevButton()}
                            {this.renderDateSelect()}
                            {this.renderNextButton()}
                        </div>
                        <Table className={"resourceTable"}>
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Booked by</th>
                                    <th>Book</th>
                                </tr>
                            </thead>
                            <tbody>
                            {slots.map((slots, index) =>
                                this.renderTimeSlot(index)
                            )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )
        }
    }
}

export class ResourcePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            resource: this.props.match.params.resource
        }
    }

    render() {
        let resourceName = this.state.resource;
        let firstLetter = resourceName[0];
        resourceName = firstLetter.toUpperCase() +  resourceName.substring(1);

        return (
            <div className={'resourcePage'}>
                <h1>&#12304;{resourceName}&#12305;</h1>
                <Resource resource={this.state.resource} serverURL={this.props.serverURL} />
            </div>
        )
    }
}
