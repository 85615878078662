import React from 'react';
import Cookies from 'js-cookie';

//--- Bootstrap ---//
import { Form, Button, Accordion, Card } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

//--- CSS ---//
import "../css/admin.css";

// ----- Accounts ----- //
export class EditAccountComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            key: this.props.index,
            initRank: this.props.rank,
            initUsername: this.props.username,
            rank: this.props.rank,
            username: this.props.username,
            password: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRankChange = this.handleRankChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handlePassChange = this.handlePassChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        this.editAccount().then( (ret) => {
            if(ret) {
                alert("Successfully edited user " + this.state.initUsername + ".");
                window.location.replace(window.location.protocol + "//" + window.location.host + "/admin/accounts");
            } else {
                alert("Error editing user " + this.state.initUsername + ".");
            }
        })
    }

    async editAccount() {
        let isAdmin = false;
        if(this.state.rank === 'admin') {
            isAdmin = true;
        }

        if(this.state.password === "") {
            const requestOptions = { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
                username: this.state.username,
                admin: isAdmin
             }) };
             const response = await fetch(this.props.serverURL + "/api/user/" + this.state.initUsername + "?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), requestOptions);

             if(response.status === 200) {
                return true;
            } else {
                return false;
            }
        } else {
            const bcrypt = require('bcryptjs');
            let hash = bcrypt.hashSync(this.state.password, '$2a$12$9GRLwgqLNgly1nY9ZHmQvu');
    
            const requestOptions = { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
                username: this.state.username,
                passwordHash: hash,
                admin: isAdmin
             }) };
             const response = await fetch(this.props.serverURL + "/api/user/" + this.state.initUsername + "?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), requestOptions);

             if(response.status === 200) {
                return true;
            } else {
                return false;
            }
        }
    }

    handleRankChange(event) {
        this.setState({
            rank: event.target.value
        })
    }

    handleUserChange(event) {
        this.setState({
            username: event.target.value
        })
    }

    handlePassChange(event) {
        this.setState({
            password: event.target.value
        })
    }

    handleDelete() {
        if (window.confirm("Are you sure you want to delete user " + this.state.initUsername)) {
            this.deleteAccount().then( (ret) => {
                if(ret) {
                    alert("User " + this.state.initUsername + " successfully deleted.");
                    window.location.replace(window.location.protocol + "//" + window.location.host + "/admin/accounts");
                } else {
                    alert("Error deleting user " + this.state.initUsername + ".");
                }
            })
        }
    }

    async deleteAccount() {
        let requestOptions = { method: 'DELETE' };
        let response = await fetch(this.props.serverURL + "/api/user/" + this.state.initUsername + "?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), requestOptions);

        if(response.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return(
            <Accordion key={this.state.key} defaultActiveKey={"1"} className={"accountListing"}>
                <Card>
                    <Accordion.Toggle className='accountList' as={Card.Header} eventKey="0">
                        <div className='accountRank'><p className='content'>{this.state.initRank}</p></div>
                        <div className='accountName'><p className='content'>{this.state.initUsername}</p></div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Form onSubmit={this.handleSubmit} className='accountEditForm'>
                                <Form.Group className={"accountEditRank"} controlId='accountRank'>
                                    <Form.Label>User Rank: </Form.Label>
                                    <select value={this.state.rank} className='form-select' onChange={this.handleRankChange}>
                                        <option key='0' value={"user"}>User</option>
                                        <option key='1' value={"admin"}>Admin</option>
                                    </select>
                                </Form.Group>
                                <Form.Group className={'accountEditUser'} controlId='accountName'>
                                    <Form.Control value={this.state.username} placeholder={"Username..."} type='username' onChange={this.handleUserChange}/>
                                </Form.Group>
                                <Form.Group className={'accountEditPass'} controlId='accountPassword' >
                                    <Form.Control value={this.state.password} placeholder="Password..." type='password' onChange={this.handlePassChange}/>
                                </Form.Group>
                                <div><button onClick={this.handleDelete} className={"accountEditDelete float-left btn btn-danger"} type="button">Delete</button></div>
                                <Button variant={"primary"} type={"submit"} className={'accountEditSubmit float-right'}>Edit</Button>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    }
}