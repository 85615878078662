import React from 'react';
import Cookies from 'js-cookie';

import { Form, Button, Row, Col } from "react-bootstrap";

//--- Bootstrap ---//
import 'bootstrap/dist/css/bootstrap.min.css';

//--- CSS ---//
import "../css/admin.css";

export class CreatePage extends React.Component {
    constructor(props) {
        super(props);

        if(this.props.match === undefined) {
            this.state = {
                isLoaded: false,
                page: 0
            };
        } else {
            let pagestate = this.props.match.params.pagestate;
            /*if(pagestate === "rooms") {
                this.state = {
                    isLoaded: false,
                    page: 1
                };
            } else*/ if(pagestate === "accounts") {
                this.state = {
                    isLoaded: false,
                    page: 2,
                    username: "",
                    usernameError: false,
                    usernameErrorMsg: "",
                    password: "",
                    passwordError: false,
                    passwordErrorMsg: "",
                    rank: 'user'
                };
            } else {
                window.location.replace(window.location.protocol + "//" + window.location.host + "/admin");
            }
        }

        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRankChange = this.handleRankChange.bind(this);
        this.handleAccountSubmit = this.handleAccountSubmit.bind(this);
    }

    async checkAdminValidity() {
        const requestOptions = { method: 'GET', headers: { 'Content-Type': 'application/json' } };
        const response = await fetch(this.props.serverURL + "/api/whoami?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), requestOptions);

        if(response.status === 200) {
            let res = await response.json();
            if(!res.isAdmin) {
                window.location.replace(window.location.protocol + "//" + window.location.host);
            }
        } else {
            window.location.replace(window.location.protocol + "//" + window.location.host);
        }
    }

    componentDidMount() {
        this.init();
    }

    init() {
        this.checkAdminValidity().then( () => {
            this.setState({
                isLoaded: true
            })
        })
    }

    // ----- Accounts ----- //
    handleUsernameChange(event) {
        this.setState({
            username: event.target.value
        })
    }

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value
        })
    }

    handleRankChange(event) {
        let rank = event.target.value;

        if(rank === "user" || rank === "admin") {
            this.setState({
                rank: event.target.value
            })
        }
    }

    handleAccountSubmit(event) {
        event.preventDefault();

        let userError, passError = false;
        let userMsg, passMsg = "";
        if(this.state.username === "") {
            userError = true;
            userMsg = "You have to enter a Username.";
        }
        if(this.state.password === "") {
            passError = true;
            passMsg = "You have to enter a Password."
        }

        this.setState({
            usernameError: userError,
            usernameErrorMsg: userMsg,
            passwordError: passError,
            passwordErrorMsg: passMsg
        }, () => {
            if(this.state.usernameError || this.state.passwordError) {
                return 0;
            } else {
                this.addAccount().then( ret => {
                    if(ret) {
                        alert("User " + this.state.username + " was successfully added.");
                        window.location.replace(window.location.protocol + "//" + window.location.host + "/admin/accounts");
                    } else {
                        alert("Error adding user " + this.state.username + ".");
                    }
                })
            }
        })
    }

    async addAccount() {
        let isAdmin = false;
        if(this.state.rank === 'admin') {
            isAdmin = true;
        }
    
        const bcrypt = require('bcryptjs');
        let hash = bcrypt.hashSync(this.state.password, '$2a$12$9GRLwgqLNgly1nY9ZHmQvu');
    
        const requestOptions = { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
            username: this.state.username,
            passwordHash: hash,
            admin: isAdmin
        }) };
        const response = await fetch(this.props.serverURL + "/api/user/" + this.state.username + "?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), requestOptions);
    
        if(response.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { isLoaded, page } = this.state;
        if(!isLoaded) {
            return (
                <div className={'loading-resource'}>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            )
        } else {
            if(page === 1) { //Rooms Create
                return (
                    <div className={'adminpanel'}>
                        <h1>Admin Panel</h1>
                        <h2>Create Room</h2>
                        <Form>
                            <Form.Group controlId='roomName'>
                                <Form.Label>Raumname</Form.Label>
                                <Form.Control />
                            </Form.Group>
                            <Form.Group controlId='timeslotDauer'>
                                <Form.Control />
                            </Form.Group>
                            <Form.Group controlId='timeslotStart' >
                                <Form.Control />
                            </Form.Group>
                            <Button>Create</Button>
                        </Form>
                    </div>
                )
            } else if(page === 2) { //Accounts Create
                return (
                    <div className={'adminpanel'}>
                        <div className={'adminCreEd'}>
                            <h1>Admin Panel</h1>
                            <h2>Create Account</h2>
                            <Form onSubmit={this.handleAccountSubmit} className='accountEditForm'>
                                <Form.Group className={"accountEditRank"} controlId='accountRank'>
                                    <Form.Label>User Rank: </Form.Label>
                                    <select className='form-select' value={this.state.rank} onChange={this.handleRankChange}>
                                        <option key='0' value={'user'}>User</option>
                                        <option key='1' value={'admin'}>Admin</option>
                                    </select>
                                </Form.Group>
                                <Form.Group as={Row} className={'accountEditUser'} controlId='accountName'>
                                    <Col>
                                        <Form.Control isInvalid={this.state.usernameError} placeholder="Username..." value={this.state.username} onChange={this.handleUsernameChange} />
                                        <Form.Control.Feedback type="invalid">{this.state.usernameErrorMsg}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className={'accountEditPass'} controlId='accountPassword' >
                                    <Col>
                                        <Form.Control isInvalid={this.state.passwordError} placeholder="Password..." type='password' value={this.state.password} onChange={this.handlePasswordChange} />
                                        <Form.Control.Feedback type="invalid">{this.state.passwordErrorMsg}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Button variant={"primary"} type={"submit"} className={'accountEditSubmit float-right'}>Create</Button>
                            </Form>
                        </div>
                    </div>
                )
            } else {
                window.location.replace(window.location.protocol + "//" + window.location.host);
            }
        }
    }
}