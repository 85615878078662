import React from 'react';
import Cookies from 'js-cookie';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';

import '../css/login.css'
import {Link} from "react-router-dom";

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user_input: '',
            pass_input: '',
            user_err: false,
            pass_err: false,
            user_err_msg: '',
            pass_err_msg: '',
            stay_logged: false
        };

        this.handleLogin = this.handleLogin.bind(this);
        this.checkUserValidity = this.checkUserValidity.bind(this);
        this.checkPasswordValidity = this.checkPasswordValidity.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleStayLogged = this.handleStayLogged.bind(this);
    }

    checkUserValidity() {
        const user = this.state.user_input;
        this.setState({user_err: false, user_err_msg: ''});

        if(user === '') {
            this.setState({user_err: true, user_err_msg: 'Username Cannot Be Empty'});
            return false;
        }

        return true;
    }

    checkPasswordValidity() {
        const pass = this.state.pass_input;
        this.setState({pass_err: false, pass_err_msg: ''});

        if(pass === '') {
            this.setState({pass_err: true, pass_err_msg: 'Password Cannot Be Empty'});
            return false;
        }

        return true;
    }

    async login() {
        const bcrypt = require('bcryptjs');
        let hash = bcrypt.hashSync(this.state.pass_input, '$2a$12$9GRLwgqLNgly1nY9ZHmQvu');

        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(this.props.serverURL + "/api/login?username=" + encodeURIComponent(this.state.user_input) + "&passwordHash=" + encodeURIComponent(hash), { headers } );

        if(response.status === 403) {
            this.setState({user_err: true, pass_err: true, pass_err_msg: 'Username/Password Incorrect'});
        } else {
            try {
                const data = await response.json();

                if(this.state.stay_logged) Cookies.set('sessionID', data.key, { expires: 30 });
                else Cookies.set('sessionID', data.key);

                window.location.replace(window.location.protocol + "//" + window.location.host);
            } catch (error) {
                console.log('login: data error');
            }
        }
    }

    handleLogin(event) {
        event.preventDefault();
        if(Cookies.get('sessionID') === undefined) {
            let user_state = this.checkUserValidity();
            let pass_state = this.checkPasswordValidity();
            if(user_state && pass_state) {
                this.login();
            }
        }
    }

    handleUsernameChange(event) {
        this.setState({user_input: event.target.value});
    }

    handlePasswordChange(event) {
        this.setState({pass_input: event.target.value});
    }

    handleStayLogged(event) {
        this.setState({stay_logged: event.target.checked});
    }

    render() {
        return(
            <div className={'login'}>
                <div className={'center'}>
                    <p className={'pre-header'}>Clemensburse</p>
                    <p className={'header'}>Sign in</p>
                    <Form onSubmit={this.handleLogin}>
                        <Form.Group as={Row} controlId={"formUsername"}>
                            <Col>
                                <Form.Control isInvalid={this.state.user_err} type={"username"} placeholder={"Username"} value={this.state.user_input} onChange={this.handleUsernameChange}/>
                                <Form.Control.Feedback type="invalid">{this.state.user_err_msg}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId={"formPassword"}>
                            <Col>
                                <Form.Control isInvalid={this.state.pass_err} type={"password"} placeholder={"Password"} value={this.state.pass_input} onChange={this.handlePasswordChange}/>
                                <Form.Control.Feedback type="invalid">{this.state.pass_err_msg}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formStayLogged">
                            <Form.Check type="checkbox" checked={this.state.stay_logged} label="Stay Logged in" onChange={this.handleStayLogged} />
                        </Form.Group>
                        <Link to={""} className={"btn btn-secondary"}>Cancel</Link>
                        <Button variant={"primary"} type={"submit"} className={'float-right'}>Login</Button>
                    </Form>
                </div>
            </div>
        )
    }
}

class Logout extends React.Component {
    constructor(props) {
        super(props);

        this.handleLogout = this.handleLogout.bind(this);
    }

    async logout() {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(this.props.serverURL + "/api/logout?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), { headers } );

        if(response.status === 200) {
            Cookies.remove('sessionID');
            window.location.reload(false);
        } else {
            alert('Logout Error: ' + response.status);
        }
    }

    handleLogout(event) {
        event.preventDefault();
        if(Cookies.get('sessionID') !== undefined) {
            this.logout();
        }
    }

    render() {
        return (
            <div className={'logout'}>
                <div className={'center'}>
                    <p className={'pre-header'}>Clemensburse</p>
                    <p className={'header'}>Sign out</p>
                    <Form onSubmit={this.handleLogout} className={'logout-btn'}>
                        <Link to={""} className={"btn btn-secondary"}>Cancel</Link>
                        <Button variant={"danger"} type={"submit"}>Logout</Button>
                    </Form>
                </div>
            </div>
        )
    }
}

export class LoginHandler extends React.Component {
    render() {
        if(Cookies.get('sessionID') === undefined) {
            if(window.location.pathname !== '/login') window.location.replace(window.location.protocol + "//" + window.location.host + "/login");
            return (
                <Login serverURL={this.props.serverURL} />
            );
        } else {
            if(window.location.pathname !== '/logout') window.location.replace(window.location.protocol + "//" + window.location.host + "/logout");
            return (
                <Logout serverURL={this.props.serverURL} />
            );
        }
    }
}