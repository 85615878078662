import React from 'react';
import Cookies from 'js-cookie';

import { Button, Table, Accordion, Card } from "react-bootstrap";

import { DateTime, Duration } from "luxon"

//--- CSS ---//
import "../css/booked.css";

export class MyPage extends React.Component {
    constructor(props) {
        super(props);
        let localDate = DateTime.local();

        this.state = {
            username: null,
            resources: null,
            startDate: localDate.toSeconds() - ((localDate.hour * 60 * 60) + (localDate.minute * 60) + (localDate.second) + (localDate.millisecond / 1000)),
            isLoaded: false,
            mySlots: []
        };
    }

    componentDidMount() {
        this.initResources();
    }

    timeToSlot(resourceID, timeInSeconds) {
        let start = this.state.resources[resourceID].timeslotStart;
        let duration = this.state.resources[resourceID].timeslotDauer;
        return ((timeInSeconds - DateTime.fromISO(start).toSeconds()) / Duration.fromISO(duration).as('seconds'));
    }

    slotToTime(resourceID, timeSlot) {
        let start = this.state.resources[resourceID].timeslotStart;
        let duration = this.state.resources[resourceID].timeslotDauer;
        return (DateTime.fromISO(start).toSeconds() + (Duration.fromISO(duration).as('seconds') * timeSlot));
    }

    async getUsername() {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(this.props.serverURL + "/api/whoami?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), { headers } );

        if(response.status !== 200) {
            window.location.replace(window.location.protocol + "//" + window.location.host);
        } else {
            try {
                let data = await response.json();

                return data.name;
            } catch (e) {
                console.error("ERROR: getting username");
            }
        }
    }

    async getMySlots(resourceID) {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(this.props.serverURL + "/api/resource/" + this.state.resources[resourceID].id + "/timeslots?start=" + this.timeToSlot(resourceID, parseInt(this.state.startDate)) + "&end=" + this.timeToSlot(resourceID, parseInt(this.state.startDate) + (14 * (24 * 60 * 60))) + "&onlyMyBooked=true&sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), { headers } );
        
        if(response.status === 200) {
            try{
                return await response.json();
            } catch(e) {
                console.error(e);
            }
        }
    }

    sortSlots(slotArray) {
        let sorted = [];
  
        for(let i = 0; i < this.state.resources.length; i++) {
            for(let j = 0; j < slotArray.length; j++) {
                if(slotArray[j].length > 0) {
                    if(slotArray[j][0].resource === this.state.resources[i].id) {
                        sorted.push(slotArray[j]);
                        continue;
                    }
                }
            }
            if(sorted.length < i + 1) sorted.push([]);
        }

        this.setState({
            mySlots: sorted
        })
    }

    async initResources() {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(this.props.serverURL + "/api/resourceList?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), { headers } );

        if(response.status !== 200) {
            window.location.replace(window.location.protocol + "//" + window.location.host);
        } else {
            try {
                let resources = await response.json();
                let username = await this.getUsername();

                this.setState({
                    username: username,
                    resources: resources
                }, () => {
                    let a = [];
                    resources.map((resource, index) =>
                        this.getMySlots(index).then(slot => {
                            a.push(slot)
                            if(a.length === resources.length) {
                                this.sortSlots(a);
                                this.setState({
                                    isLoaded: true
                                })
                            }
                        })
                    )
                });
            } catch (e) {
                console.error("Error: loading resource");
            }
        }
    }

    async unbook(resourceID, timeSlot) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: ""
        };
        const response = await fetch(this.props.serverURL + "/api/resource/" + this.state.resources[resourceID].id + "/timeslots/" + timeSlot + "?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), requestOptions);

        if(response.status === 200) {
            window.location.reload();
        }
    }

    renderUnbookButton(resourceID, timeSlot) {
        return <Button className={timeSlot} variant={"danger"} onClick={() => this.unbook(resourceID, timeSlot)}>unbook</Button>
    }

    renderSlots(slot, index, resourceID) {
        let time = this.slotToTime(resourceID, (slot.timeslotNumber));
        let time2 = this.slotToTime(resourceID, (slot.timeslotNumber + 1));

        return(
            <tr key={index}>
                <td>{DateTime.fromSeconds(time).setLocale("de").toFormat('EEE')} {DateTime.fromSeconds(time).setLocale("de").toFormat('dd.MM.yyyy')}</td>
                <td>{DateTime.fromSeconds(time).toFormat('HH:mm')} - {DateTime.fromSeconds(time2).toFormat('HH:mm')}</td>
                <td>{this.renderUnbookButton(resourceID, slot.timeslotNumber)}</td>
            </tr>
        )
    }

    renderMyBooked(resourceID) {
        if(this.state.mySlots[resourceID].length > 0) {
            return(
                <div key={this.state.resources[resourceID].displayName} className={"mybooked"}>
                    <div className="d-flex justify-content-center">
                            <Accordion defaultActiveKey={"0"}>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        {this.state.resources[resourceID].displayName}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <Table className={"resourceTable"}>
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Time</th>
                                                        <th>Unbook</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.mySlots[resourceID].map((slot, index) => 
                                                        this.renderSlots(slot, index, resourceID)
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                </div>
            )
        }
    }

    render() {
        const { isLoaded, resources } = this.state;

        if(!isLoaded) {
            return (
                <div className={'loading-resource'}>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            )
        } else {
            let empty = true;
            for (let i = 0; i < this.state.mySlots.length; i++) {
                if (this.state.mySlots[i].length > 0) {
                    empty = false;
                    break;
                }
            }

            if(empty) {
                return (
                    <div className={"mypage"}>
                        <h1>Gebuchte Slots</h1>
                        <h2>{this.state.username}</h2>
                        <p>Sie haben nichts gebucht.</p>
                    </div>
                )
            } else {
                return (
                    <div className={"mypage"}>
                        <h1>Gebuchte Slots von: {this.state.username}</h1>
                        {resources.map((resource, index) =>
                            this.renderMyBooked(index)
                        )}
                    </div>
                )
            }
        }
    }
}