import React from 'react';
import Cookies from 'js-cookie';

import { Form, Table, Accordion, Card } from "react-bootstrap";

//--- Component ---//
import { EditAccountComponent } from './edit'

//--- Routing ---//
import {
    Link
} from "react-router-dom";

//--- Bootstrap ---//
import 'bootstrap/dist/css/bootstrap.min.css';

//--- CSS ---//
import "../css/admin.css";

//--- SVG ---//
import { ReactComponent as RoomsSVG } from "../ico/room_preferences_white_24dp.svg";
import { ReactComponent as AccountsSVG } from "../ico/manage_accounts_white_24dp.svg";

export class AdminPage extends React.Component {
    constructor(props) {
        super(props);

        if(this.props.match === undefined) {
            this.state = {
                isLoaded: false,
                page: 0,
                username: '',
                search: '',
                rooms: null,
                roomsLoaded: false,
                accounts: null,
                accountsLoaded: false
            };
        } else {
            let pagestate = this.props.match.params.pagestate;
            /*if(pagestate === "rooms") {
                this.state = {
                    isLoaded: false,
                    page: 1,
                    search: '',
                    rooms: null,
                    roomsLoaded: false,
                    accounts: null,
                    accountsLoaded: false
                };
            } else*/ if(pagestate === "accounts") {
                this.state = {
                    isLoaded: false,
                    page: 2,
                    search: '',
                    rooms: null,
                    roomsLoaded: false,
                    accounts: null,
                    accountsLoaded: false
                };
            } else {
                window.location.replace(window.location.protocol + "//" + window.location.host + "/admin");
            }
        }

        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    async checkAdminValidity() {
        const requestOptions = { method: 'GET', headers: { 'Content-Type': 'application/json' } };
        const response = await fetch(this.props.serverURL + "/api/whoami?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), requestOptions);

        if(response.status === 200) {
            let res = await response.json();
            if(!res.isAdmin) {
                window.location.replace(window.location.protocol + "//" + window.location.host);
            } else {
                this.setState({
                    username: res.name
                })
            }
        } else {
            window.location.replace(window.location.protocol + "//" + window.location.host);
        }
    }

    componentDidMount() {
        this.init();
    }

    init() {
        this.checkAdminValidity().then( () => {
            this.setState({
                isLoaded: true
            })
        })
    }

    // ----- Rooms ----- //

    async getRooms() {
        const requestOptions = { method: 'GET', headers: { 'Content-Type': 'application/json' } };
        const response = await fetch(this.props.serverURL + "/api/resourceList?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), requestOptions);

        if(response.status === 200) {
            let rooms = await response.json();
            this.setState({
                accountsLoaded: false,
                rooms: rooms
            }, () => {
                this.setState({
                    roomsLoaded: true
                })
            });
        }
    }

    renderRooms() {
        const { rooms } = this.state;

        return(
            <tbody>
                {rooms.map((room, index) => {
                    let roomName = room.displayName;
                    roomName = roomName.toLowerCase();
                    let search = this.state.search;
                    search = search.toLowerCase();
                    if(roomName.includes(search)) {
                        return (
                            <tr key={index}>
                                <td className='roomName'><p className='content'>{room.displayName}</p></td>
                                <td className='roomEdit'><Link to={"/admin/edit/room?id=0"} className={"content btn btn-primary"}>Edit</Link></td>
                                <td className='roomDelete'><button className={"content btn btn-danger"} type="button">Delete</button></td>
                            </tr>
                        )
                    } else {
                        return (
                            <></>
                        )
                    }
                })}
            </tbody>
        )
    }

    // ----- Accounts ----- //
    async getAccounts() {
        const requestOptions = { method: 'GET', headers: { 'Content-Type': 'application/json' } };
        const response = await fetch(this.props.serverURL + "/api/users?sessionKey=" + encodeURIComponent(Cookies.get('sessionID')), requestOptions);

        if(response.status === 200) {
            let accounts = await response.json();
            this.setState({
                roomsLoaded: false,
                accounts: accounts.sort((a, b) => a.username.localeCompare(b.username))
            }, () => {
                this.setState({
                    accountsLoaded: true
                })
            });
        }
    }

    handleSearchChange(event) {
        this.setState({search: event.target.value});
    }

    renderAccounts() {
        const { accounts } = this.state;

        return(
            <div className='accountListing'>
                {accounts.map((account, index) => {
                    let accountName = account.username;
                    accountName = accountName.toLowerCase();
                    let search = this.state.search;
                    search = search.toLowerCase();
                    if(accountName.includes(search)) {
                        let rank = 'user';
                        if(account.admin) {
                            rank = 'admin'
                        }
                        return (
                            <EditAccountComponent key={index} index={index} serverURL={this.props.serverURL} rank={rank} username={account.username}/>
                        )
                    } else {
                        return (
                            <></>
                        )
                    }
                })}
            </div>
        )
    }

    render() {
        const { isLoaded, page } = this.state;
        if(!isLoaded) {
            return (
                <div className={'loading-resource'}>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            )
        } else {
            if(page === 0) {
                return(
                    <div className={'adminpanel'}>
                        <h1>Admin Panel</h1>
                        <h2>{this.state.username}</h2>
                        <div className='center'>
                            <div className={'navigation'}>
                                <Link to={'/admin/rooms'} className={"block-nav block-nav-danger"}>
                                    <RoomsSVG className={"svg"}/>
                                    <p>Rooms - WIP</p>
                                </Link>
                                <Link to={'/admin/accounts'} className={"block-nav block-nav-primary"}>
                                    <AccountsSVG className={"svg"}/>
                                    <p>Accounts</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                )
            } else if(page === 1) { // --- Rooms --- //
                if(!this.state.roomsLoaded) {
                    this.getRooms();
                    return (
                        <div className={'loading-resource'}>
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className={'adminpanel'}>
                            <h1>Admin Panel</h1>
                            <h2>{this.state.username}</h2>
                            <div className="create">
                                <button className={"create btn btn-primary"} type="button">Create New</button>
                            </div>
                            <Form className="search">
                                <Form.Group controlId={"formSearch"}>
                                    <Form.Control
                                        placeholder="Search..."
                                        value={this.state.search}
                                        onChange={this.handleSearchChange}
                                    />
                                </Form.Group>
                            </Form>
                            <div className="d-flex justify-content-center">
                                <Accordion defaultActiveKey={"0"}>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="0">
                                            Rooms
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <Table className={"resourceTable"}>
                                                    {this.renderRooms()}
                                                </Table>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                        </div>
                    )
                }
            } else if(page === 2) { // --- Accounts --- //
                if(!this.state.accountsLoaded) {
                    this.getAccounts();
                    return (
                        <div className={'loading-resource'}>
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className={'adminpanel'}>
                            <h1>Admin Panel</h1>
                            <h2>{this.state.username}</h2>
                            <div className="create">
                                <Link to='create/accounts' className={"create btn btn-primary"}>Create New</Link>
                            </div>
                            <Form className="search">
                                <Form.Group controlId={"formSearch"}>
                                    <Form.Control
                                        placeholder="Search..."
                                        value={this.state.search}
                                        onChange={this.handleSearchChange}
                                    />
                                </Form.Group>
                            </Form>
                            <div className="d-flex justify-content-center">
                                <Accordion defaultActiveKey={"0"}>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="0">
                                            Accounts
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                {this.renderAccounts()}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                        </div>
                    )
                }
            } else {
                window.location.replace(window.location.protocol + "//" + window.location.host);
            }
        }
    }
}